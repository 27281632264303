<template>
	<div class="component component-title-map" v-if="constituency">
		<GmapMap
			:center="latlong"
			:zoom="zoom"
			:options="mapOptions"
			map-type-id="roadmap"
			style="width: 100%; height: 400px">

			<GmapPolyline v-if="constituency" :strokeColor="strokeColor" :path="boundaryPath"></GmapPolyline>
		</GmapMap>
		<div class="overlay">
			<h3>{{title}}</h3>
		</div>
	</div>
</template>
<script>
import MapMixin from './MapMixin';
import axios from "axios";

export default {
	mixins: [
		MapMixin
	],
	components: {
	},
	props: [
		'latitude',
		'longitude',
		'constituency_slug',
		'title'
	],
	data() {
		return {
			endpoints: {
				search: '/api/constituencies/search/',
				slug: '/api/constituencies/',
				all: '/api/constituencies'
			},
			zoom: 10,
			constituency: null
		}
	},
	mounted() {
		console.log('constituency', this.constituency_slug);
		this.search(this.constituency_slug)
	},
	methods: {

		/**
		 *
		 * @param query
		 */
		search: function (query) {
			console.log('QUERY', query);

			axios.get(this.endpoints.slug + query).then(
				(resp) => {
					console.log('RESP ROW', resp.data);
					// this.result = resp.data;
					if (resp.data) {
						this.constituency = resp.data;
					} else {
						this.constituency = null;
					}
				},
				(error) => {
					console.warn('A redirect error occurred', error);
				}
			)
		},
	},
	computed: {

		/**
		 * @todo set default externally
		 */
		latlong: function () {
			if (!this.constituency) {
				// Default
				return {lat: 52.62087, lng: -1.2};
			} else {
				return {lat: parseFloat(this.constituency.latitude), lng: parseFloat(this.constituency.longitude)};
			}
		},

		/**
		 *
		 */
		boundaryPath: function () {
			if (!this.constituency) return [];

			let path = [],
				parts = this.constituency.boundary.split(" ");

			for (let i=0; i<parts.length; i++) {
				let lnglat = parts[i].split(',');

				path.push({
					lat: parseFloat(lnglat[1]),
					lng: parseFloat(lnglat[0])
				});
			}

			return path;
		}
	}
}
</script>
