import axios from "axios";

export default {
	props: [
		'defaultConstituencyId'
	],
	data() {
		return {
			endpoints: {
				search: '/api/constituencies/search/',
				all: '/api/constituencies'
			},
			constituencyId: null,
			all: [],
			postcode: null
		}
	},
	mounted() {
		this.loadAll();
	},
	methods: {

		/**
		 *
		 */
		loadAll: function () {
			axios.get(this.endpoints.all).then(
				(resp) => {
					this.all = resp.data.sort((a, b) => a.name > b.name);
				},
				(error) => {
					console.warn('A redirect error occurred', error);
				}
			)
		},

		/**
		 *
		 * @param event
		 * @param query
		 */
		search: function (event, query) {
			event && event.preventDefault();

			axios.get(this.endpoints.search + query).then(
				(resp) => {
					this.stage = 2;
					// this.result = resp.data;
					if (resp.data) {
						this.constituencyId = resp.data.id;
					} else {
						this.constituencyId = null;
					}
				},
				(error) => {
					console.warn('A redirect error occurred', error);
				}
			)
		},

		/**
		 *
		 */
		searchPostcode: function () {
			let postcode = this.postcode;

			this.search(null, postcode);
		}
	}
}
