<script>
import md5 from 'crypto-js/md5';
import ConstituencyMixin from "@js/components/ConstituencyMixin.js";
export default {
	mixins: [
		ConstituencyMixin
	],
	props: [
		'defaultEmail',
		'defaultPostcode'
	],
	data: function () {
		return {
			email: this.defaultEmail,
			postcode: this.defaultPostcode,
			constituencyId: this.defaultConstituencyId
		}
	},
	computed: {

		/**
		 *
		 * @return {*}
		 */
		gravatarHash: function () {
			return md5(this.email)
		},

		/**
		 *
		 */
		gravatarSrc: function () {
			return 'https://www.gravatar.com/avatar/' + this.gravatarHash + '?s=200&d=identicon'
		}
	}
}
</script>
