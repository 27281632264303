<template>
	<div class="component component-find-constituency form">

		<div v-if="stage === 2">
			<button @click="previousStage" class="previous">Previous</button>
		</div>

		<h2>Find My Constituency</h2>

		<div class="postcode-search search-overlay" v-if="stage === 1">
			<div class="search-button-wrap">
				<input class="postcode" type="text" placeholder="Postcode" v-model="query" />
				<button class="search" @click="search($event, query)">Search</button><br />
			</div>
			<button class="use-location">Use My Location</button>
		</div>

		<div class="constituency-search search-overlay" v-if="stage === 2">
			<select v-model="constituencyId" class="form-select">
				<option v-for="constituency in all" :key="constituency.id" :value="constituency.id">{{constituency.name}}</option>
			</select>
			<a class="view-constituency" v-if="constituency" :href="this.redirectTarget + constituency.slug + '?postcode=' + query">View Constituency</a>
		</div>
	</div>
</template>
<script>
import axios from "axios";
import ConstituencyMixin from "@js/components/ConstituencyMixin";

export default {
	mixins: [
		ConstituencyMixin
	],
	props: [
		'target',
		'title'
	],
	components: {
	},
	data() {
		return {
			query: '',
			all: [],
			stage: 1,
			result: {}
		}
	},
	watch: {
		constituencyId: function () {

			// If valid
			if (this.constituency) {
				this.$emit('chooseConstituency', this.constituency);
			}
		}
	},
	computed: {

		/**
		 *
		 */
		redirectTarget: function () {
			if (this.target) {
				return this.target;
			} else {
				return '/constituencies/';
			}
		},

		/**
		 *
		 * @returns {*|null}
		 */
		constituency: function () {
			let constituencyId = this.constituencyId,
				constituencies = this.all;

			if (!constituencyId || !constituencies) return null;

			let found = constituencies.filter(function (el) {
				return el.id === constituencyId;
			})[0];

			return found;
		}
	},
	methods: {

		/**
		 *
		 */
		previousStage: function () {
			this.stage--;
		}
	}
}
</script>
