export default {
	data() {
		return {
			markers: [],
			zoom: 7,
			constituency: null,
			strokeColor: '#4E7CAC',
			waterColour: '#d6ebff',
			landColour: '#4E7CAC'
		}
	},
	computed: {

		/**
		 *
		 */
		mapOptions: function () {
			return {
				zoomControl: false,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
				disableDefaultUI: false,
				styles: this.mapTheme
			}
		},

		/**
		 *
		 */
		mapTheme: function () {
			return [
				{
					"featureType": "poi",
					"elementType": "all",
					"stylers": [
						{
							"hue": "#000000"
						},
						{
							"saturation": -100
						},
						{
							"lightness": -100
						},
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi",
					"elementType": "all",
					"stylers": [
						{
							"hue": "#000000"
						},
						{
							"saturation": -100
						},
						{
							"lightness": -100
						},
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "administrative",
					"elementType": "all",
					"stylers": [
						{
							"hue": "#000000"
						},
						{
							"saturation": 0
						},
						{
							"lightness": -100
						},
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "labels",
					"stylers": [
						{
							"hue": "#ffffff"
						},
						{
							"saturation": -100
						},
						{
							"lightness": 100
						},
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "labels",
					"stylers": [
						{
							"hue": "#000000"
						},
						{
							"saturation": -100
						},
						{
							"lightness": -100
						},
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road.local",
					"elementType": "all",
					"stylers": [
						{
							"hue": "#ffffff"
						},
						{
							"saturation": -100
						},
						{
							"lightness": 100
						},
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "water",
					"elementType": "geometry",
					"stylers": [
						{
							"hue": this.waterColour
						},
						{
							"saturation": 100
						},
						{
							"lightness": 0
						},
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "transit",
					"elementType": "labels",
					"stylers": [
						{
							"hue": "#000000"
						},
						{
							"saturation": 0
						},
						{
							"lightness": -100
						},
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "labels",
					"stylers": [
						{
							"hue": "#000000"
						},
						{
							"saturation": -100
						},
						{
							"lightness": -100
						},
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "geometry",
					"stylers": [
						{
							"hue": "#bbbbbb"
						},
						{
							"saturation": -100
						},
						{
							"lightness": 26
						},
						{
							"visibility": "on"
						}
					]
				},
				{
					"featureType": "landscape",
					"elementType": "geometry",
					"stylers": [
						{
							"hue": this.landColour
						},
						{
							"saturation": 100
						},
						{
							"lightness": 50
						},
						{
							"visibility": "on"
						}
					]
				}
			];
		},
	}
}
