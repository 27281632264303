<template>
	<div class="component component-header-map">
		<GmapMap
			:center="latlong"
			:zoom="zoom"
			:options="mapOptions"
			map-type-id="roadmap"
			style="width: 100%; height: 400px">

			<GmapPolyline
				v-if="this.constituency"
				:strokeColor="strokeColor"
				:path="boundaryPath"></GmapPolyline>

		</GmapMap>
		<div class="overlay">
			<find-consituency
				@chooseConstituency="chooseConstituency"
				:target="target"
				:title="title"
			></find-consituency>
		</div>
	</div>
</template>
<script>
import MapMixin from './MapMixin.js';

export default {
	mixins: [
		MapMixin
	],
	components: {
	},
	props: [
		'latitude',
		'longitude',
		'target',
		'title'
	],
	computed: {

		/**
		 *
		 */
		latlong: function () {
			if (this.constituency) {
				return {lat: parseFloat(this.constituency.latitude), lng: parseFloat(this.constituency.longitude)};
			}

			return {lat: this.latitude, lng: this.longitude};
		},

		/**
		 *
		 */
		boundaryPath: function () {
			if (!this.constituency) return [];

			let path = [],
				parts = this.constituency.boundary.split(" ");

			for (let i=0; i<parts.length; i++) {
				let lnglat = parts[i].split(',');

				path.push({
					lat: parseFloat(lnglat[1]),
					lng: parseFloat(lnglat[0])
				});
			}

			return path;
		}
	},
	methods: {

		/**
		 *
		 * @param constituency
		 */
		chooseConstituency: function (constituency) {
			this.constituency = constituency;
			this.zoom = 11;
		}
	}
}
</script>
