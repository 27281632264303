<script>
export default {
	mounted() {
		let trigger = this.$el.querySelector('[data-menu-trigger]'),
			toggle = this.$el;

		toggle.classList.add('closed');

		trigger.addEventListener('mousedown', () => {
			if (toggle.classList.contains('closed')) {
				trigger.classList.add('open');
				toggle.classList.add('open');
				toggle.classList.remove('closed');
			} else {
				trigger.classList.remove('open');
				toggle.classList.add('closed');
				toggle.classList.remove('open');
			}
		});
	}
}
</script>
