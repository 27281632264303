<script>
import md5 from 'crypto-js/md5';
import ConstituencyMixin from "@js/components/ConstituencyMixin.js";
export default {
	props: [
		'defaultPostcode'
	],
	mixins: [
		ConstituencyMixin
	],
	data: function () {
		return {
			postcode: this.defaultPostcode,
			constituencyId: this.defaultConstituencyId
		}
	},
	methods: {
	}
}
</script>
